import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import api from '../../service/api';
import UsoCard from '../../components/uso-card';
import LoadingComponent from '../../components/loading-component/index.jsx';
import { ROUTE_404 } from '../../constants/routes.js';
import { ST__PERMISSOES } from '../../constants/storagekey.js';
import { FaBan, FaCaretDown, FaFilter, FaMagnifyingGlass } from 'react-icons/fa6';

function UsoSiaac() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [processando, setProcessando] = useState(false);
  const [mais, setMais] = useState(true);
  const [contratos, setContratos] = useState([]);
  const [tipoBusca, setTipoBusca] = useState('2');
  const [pesquisa, setPesquisa] = useState('');
  var pg = 0;

  function GetTipoBusca(e) {
    setTipoBusca(e.target.value);
  }

  async function GetContratosUsoSiaac(indReset, contrato) {
    pg = indReset ? 1 : pagina + 1;
    setMais(false);

    if (pesquisa?.length > 0 && tipoBusca === 1) {
      contrato = pesquisa;
    }

    api
      .get(`/v1/usosiaac/contratos`, {
        params: {
          tipo_busca: tipoBusca,
          q: pesquisa,
          pagina: pg,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (indReset) {
            setContratos(response.data);
          } else {
            setContratos((oldArray) => [...oldArray, ...response.data]);
            setPagina(pagina + 1);
          }
        } else {
          if (response.status === 401) {
            if (indReset) {
              setContratos([]);
            }
            setMais(false);
          } else {
            console.log('Ocorreu algum erro na requisição de contratos: ' + response.statusText);
          }
        }
        setProcessando(false);
        setMais(response.data.length >= 15);
        setLoading(false);
      })
      .catch((err) => {
        setProcessando(false);
        if (err.response.status === 401) {
          if (indReset) {
            setContratos([]);
          }
          setMais(false);
        } else {
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    GetContratosUsoSiaac(true, '');
  }, []);

  useEffect(() => {
    const permissao = JSON.parse(localStorage.getItem(ST__PERMISSOES));
    if (permissao.uso_siaac === 0) {
      navigate(ROUTE_404);
    }
  }, []);

  return (
    <div>
      <div className="container shadow-lg rounded-2 mt-3">
        <div className="row justify-content-between align-items-center mb-2 mt-3">
          <div className="col-md-6 col-xl-9 mb-1">
            <a
              href="#collapseExample"
              className="btn-colapse"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <h5 className="text-dark mt-3 titulo">Uso do SIAAC</h5>
            </a>
          </div>

          <div className="col-md-6 col-xl-2 mb-1">
            <div className="d-flex btn-group mt-3" role="group" aria-label="Bloquear Clientes">
              <button className="btn btn-danger" disabled>
                <div className="">
                  <span>
                    <FaBan /> Bloquear Cliente
                  </span>
                </div>
              </button>
            </div>
          </div>

          <div className="collapse" id="collapseExample">
            <div className="card card-body mt-3">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <label htmlFor="cmbPosicao">Pesquisar por</label>
                  <select onChange={GetTipoBusca} id="cmbPosicao" className="form-select mb-2" aria-label="Selecionar">
                    <option value="1">Contrato</option>
                    <option value="2" selected>
                      Razão Social/Fantasia
                    </option>
                  </select>
                </div>
                <div className="col-md-7 col-sm-12">
                  <label htmlFor="floatingInput">Pesquisa</label>
                  <input
                    onChange={(e) => setPesquisa(e.target.value)}
                    type="text"
                    className="form-control mb-2"
                    id="floatingInput"
                    placeholder="Pesquisar"
                  />
                </div>
                <div className="col-md-2 col-sm-12 align-items-center mt-4">
                  <button className="btn btn btn-primary w-100" onClick={() => GetContratosUsoSiaac(true)}>
                    <div>
                      <FaMagnifyingGlass className="me-2" />
                      <small>Pesquisar...</small>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* LISTAGEM DE REGISTROS */}
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="row ps-2 mt-3">
              <div className="row mb-3 gx-2 gy-2 clientes" data-bss-baguettebox>
                {contratos.map((a, i) => {
                  return <UsoCard key={i} id={i} contrato={a.contrato} cliente={a.empresa} total={a.total} />;
                })}
              </div>
            </div>
            {/* BUSCAR MAIS REGISTROS */}
            {contratos.length === 0 ? (
              <a
                href="#collapseExample"
                className="btn-colapse"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <div>
                  <span className="d-block text-center">
                    <div>
                      <h5 className="text-secondary">Nenhum uso do SIAAC encontrado!</h5>
                      <h6 className="pb-5 text-secondary">
                        Tente fazer algum filtro <FaFilter />
                      </h6>
                    </div>
                  </span>
                </div>
              </a>
            ) : null}
          </>
        )}

        {!processando && mais ? (
          <span className="d-block text-center mt-3 mb-5">
            <button className="btn btn-lg btn-outline-secondary mb-3" onClick={(e) => GetContratosUsoSiaac(false, '')}>
              <div>
                <FaCaretDown className="me-2" />
                <small>Carregar mais clientes...</small>
              </div>
            </button>
          </span>
        ) : loading ? null : (
          <div className="d-block text-center mt-2 pb-2">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsoSiaac;
