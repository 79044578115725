import React, { useEffect, useState } from 'react';
import { FaKey } from 'react-icons/fa6';
import { ST__C_ATIVA_SIAAC } from '../../constants/storagekey.js';
import api from '../../service/api.js';
import Modal from 'react-modal/lib/components/Modal';
import Close from '../../assets/close.png';
import { max } from 'moment';
import BtnCopy from '../btn-copy/index.jsx';

function ChaveDeAtivacao({ className }) {
  const [chave, setChave] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function GetChaveDeAtivacao() {
    const chaveLocal = localStorage.getItem(ST__C_ATIVA_SIAAC);
    if (chaveLocal) {
      setChave(chaveLocal);
    } else {
      api.get('/v1/sistema/chave/ativacao').then((result) => {
        setChave(result.data);
        localStorage.setItem(ST__C_ATIVA_SIAAC, result.data);
      });
    }
  }

  function abrirModal(e) {
    e.preventDefault();
    setModalIsOpen(true);
  }

  function fecharModal(e) {
    e.preventDefault();
    setModalIsOpen(false);
  }

  useEffect(() => {
    GetChaveDeAtivacao();
  }, []);

  const ModalChave = (props) => {
    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        preventScroll={false}
        overlayClassName="react-modal-overlay"
        className="react-modal-content-chave"
      >
        <button type="button" onClick={props.onRequestClose} className="react-modal-close">
          <img src={Close} alt="Fechar" />
        </button>

        <div className="container-fluid">
          <div className="col-12 mt-0">
            <h4 className="mt-2-mb-4">Chave de Ativação SIAACFIRE</h4>
          </div>

          <div className="d-flex align-items-center my-4 justify-content-center">
            <p className="p-0 m-0">
              <b>{chave}</b> <BtnCopy text={chave} />
            </p>
          </div>
          <div className="row">
            <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
              <button type="button" className="btn btn-sm btn-danger" onClick={props.onRequestClose}>
                Fechar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <button className={className} onClick={abrirModal}>
        <FaKey /> Ativação SIAACFIRE
      </button>
      <ModalChave isOpen={modalIsOpen} onRequestClose={fecharModal} />
    </>
  );
}

export default ChaveDeAtivacao;
