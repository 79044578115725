import React, { useEffect, useState } from 'react';
import ModalInfoOs from '../../../../components/os-finalizadas-card/modal-info-os/index.jsx';
import { FaCircleInfo } from 'react-icons/fa6';
import { useQuery } from '@tanstack/react-query';
import { GetListaOs } from '../../../../service/fetchers/atendimentos.js';
import { maskVersao } from '../../../../util/maskdoc.js';

function FormAtualizacao({ setFocus, setVersao, versao, listaOs, addLista }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [infoModalOs, setInfoModalOs] = useState({ ocorrencia: '' });
  const [os, setOs] = useState([]);

  function openModal(infos) {
    setInfoModalOs(infos);
    setModalOpen(true);
  }

  function modalClose() {
    setInfoModalOs({ ocorrencia: '' });
    setModalOpen(false);
  }

  const {
    data: listaOsSelecionar,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['ListaOsAtualizar'],
    queryFn: () => GetListaOs({ posicao: ['A'] }),
    staleTime: 1000 * 60 * 30,
  });

  useEffect(() => {
    if (listaOsSelecionar && listaOsSelecionar?.length > 0) {
      setOs(listaOsSelecionar.sort((a, b) => (a.cliente < b.cliente ? -1 : a.cliente > b.cliente ? 1 : 0)));
    }
  }, [listaOsSelecionar]);

  return (
    <>
      <ModalInfoOs onRequestClose={modalClose} isOpen={modalOpen} info={infoModalOs} />
      <div className="mb-3 col-md-4 col-lg-4 col-sm-6">
        <div className="form-floating">
          <input
            onChange={(e) => setVersao(e.target.value)}
            value={maskVersao(versao)}
            onFocus={() => setFocus(false)}
            onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
            type="text"
            autoComplete="off"
            className="form-control"
            id="versao"
            name="versao"
            placeholder="versao"
          />
          <label htmlFor="versao" className="obrigatorio">
            Versão do Sistema
          </label>
        </div>
      </div>
      <div className="mb-3 col-md-4 col-lg-4 col-sm-6">
        <label htmlFor="dropdown">Lista de OS Relacionadas</label>
        <div className="dropdown">
          <button
            className="btn dropdown-toggle nowrap"
            type="button"
            data-bs-auto-close="outside"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {listaOs.length === 0 ? 'Selecione' : listaOs.join(', ').replace(/,(?=[^,]*$)/, ' e')}
          </button>
          <ul className="dropdown-menu">
            {!isLoading && !isFetching ? (
              !isError ? (
                os?.length === 0 ? (
                  <li className="dropdown-item">
                    <label htmlFor="os">Nenhuma OS encontrada</label>
                  </li>
                ) : (
                  os?.map((os) => (
                    <li className="dropdown-item" key={os.id}>
                      <input
                        id={os.id}
                        type="checkbox"
                        className="me-1"
                        checked={listaOs.findIndex((x) => Number(x) === Number(os.id)) !== -1 ? true : false}
                        value={os.id}
                        onChange={addLista}
                      />
                      <label htmlFor={os.id}>
                        <strong>{os.id}</strong> - {os.cliente}
                        <button
                          style={{ border: 0, backgroundColor: 'transparent' }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openModal(os);
                          }}
                        >
                          <FaCircleInfo />
                        </button>
                      </label>
                    </li>
                  ))
                )
              ) : (
                <li className="dropdown-item">
                  <label htmlFor="os">Houve um erro ao buscar a listagem de OS</label>{' '}
                </li>
              )
            ) : (
              <li className="dropdown-item">
                <label htmlFor="os">Carregando...</label>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default FormAtualizacao;
