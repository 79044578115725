import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../../service/api';
import { maskphone } from '../../../util/maskdoc';
import './style.css';
import { UsuarioContext } from '../../../context/userContext';
import { ROUTE_USUARIOS } from '../../../constants/routes.js';
import { FaEraser, FaFloppyDisk } from 'react-icons/fa6';
import Titulo from '../../../components/titulo/index.jsx';

function UsuariosEdicao() {
  const { permissoesUsuario } = useContext(UsuarioContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const [tipoEdicao, setTipoEdicao] = useState('Tipo de Edição');

  const [Nome, setNome] = useState('');
  const [Login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [setor, setSetor] = useState('S');
  const [departamento, setDepartamento] = useState('Suporte');
  const [Administrador, setAdministrador] = useState('N');
  const [Ativo, setAtivo] = useState('D');
  const [Telefone1, setTelefone1] = useState('');
  const [Telefone2, setTelefone2] = useState('');
  const [cargo, setCargo] = useState('');
  const [UF, setUF] = useState('');
  const [listaUf, setListaUf] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);
  const [idCidade, setIdCidade] = useState('');
  const [registraPonto, setRegistraPonto] = useState(1);
  const [dataAdmissao, setDataAdmissao] = useState(id > 0 ? '' : new Date().toISOString().split('T')[0]);

  const getUsuario = async () => {
    api.get(`v1/usuarios/${id}`).then((response) => {
      setNome(response.data[0].NOME);
      setLogin(response.data[0].LOGIN.split('@')[0]);
      setSetor(response.data[0].SETOR);
      setAdministrador(response.data[0].ADMINISTRADOR);
      setAtivo(response.data[0].STATUS);
      setTelefone1(response.data[0].telefone_1);
      setTelefone2(response.data[0].telefone_2);
      setDataAdmissao(response.data[0].data_admissao.split('T')[0]);
      setCargo(response.data[0].cargo);
      setRegistraPonto(response.data[0].registra_ponto);
      setIdCidade(response.data[0].cidade_id);
      api.get('/v1/buscar_cidade', { params: { id: response.data[0].cidade_id } }).then((res) => {
        setUF(res.data[0].uf);
        GetCidades(res.data[0].uf);
      });
    });
  };

  useEffect(() => {
    if (setor == 'S') {
      setDepartamento('Suporte');
    }
    if (setor == 'D') {
      setDepartamento('Desenvolvimento');
    }
  }, [setor]);

  useEffect(() => {
    api
      .get('/v1/uf')
      .then((response) => {
        setListaUf(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (id > 0) {
      getUsuario();
      setTipoEdicao('Alterar Usuário...');
    } else {
      setTipoEdicao('Novo Usuário...');
    }
  }, []);

  function GetCidades(uf) {
    setUF(uf);
    api
      .get('/v1/cidades', {
        params: {
          uf,
        },
      })
      .then((response) => {
        setListaCidades(response.data);
      })
      .catch((err) => {
        setListaCidades([]);
        console.log(err);
      });
  }

  const Limpar = () => {
    setNome('');
    setLogin('');
    setSenha('');
    setTelefone1('');
    setTelefone2('');
  };

  const NovoUsuario = (e) => {
    api
      .post('/v1/usuarios/registro', {
        nome: Nome,
        login: Login + '@cm-sistemas.com',
        senha,
        setor,
        administrador: Administrador,
        status: Ativo,
        nome_funcionario: Nome,
        cargo,
        departamento,
        registra_ponto: registraPonto,
        cidade_id: idCidade,
        telefone_1: Telefone1.replace(/\D/g, ''),
        operadora_telefone_1: '',
        telefone_2: Telefone2.replace(/\D/g, ''),
        operadora_telefone_2: '',
        data_admissao: dataAdmissao,
      })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Usuário registrado!',
          confirmButtonText: 'OK',
          preConfirm: () => {
            navigate(ROUTE_USUARIOS);
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response.data.erro || error.response.data.message,
        });
      });
  };

  const EditarUsuario = (e) => {
    api
      .patch('/v1/usuarios/editar', {
        id,
        nome: Nome,
        login: Login + '@cm-sistemas.com',
        senha,
        setor,
        administrador: Administrador,
        status: Ativo,
        cargo,
        departamento,
        registra_ponto: registraPonto,
        cidade_id: idCidade,
        telefone_1: Telefone1 ? Telefone1.replace(/\D/g, '') : '',
        telefone_2: Telefone2 ? Telefone2.replace(/\D/g, '') : '',
        data_admissao: dataAdmissao,
      })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Usuário Editado!',
          confirmButtonText: 'OK',
          preConfirm: () => {
            navigate(ROUTE_USUARIOS);
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error,
        });
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (id == 0) {
      NovoUsuario(e);
    }
    if (id > 0) {
      EditarUsuario(e);
    }
  };

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <Titulo principal={false} titulo={tipoEdicao} retorno={'usuarios'} />
        <div className="row ps-2 mt-3">
          <div className="row mb-3 gx-2 gy-2 ps-3 pe-3" data-bss-baguettebox>
            <form id="form-usuario" action="">
              <div className="row">
                <div className="mb-3 col-lg-6">
                  <div className="form-floating">
                    <input
                      value={Nome}
                      type="text"
                      className="form-control"
                      id="nomeUsuario"
                      name="nomeUsuario"
                      placeholder="Nome"
                      onChange={(e) => setNome(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="nomeUsuario" className="obrigatorio">
                      Nome
                    </label>
                  </div>
                </div>
                <div className="mb-3 col-lg-6">
                  <div className="form-floating input-group">
                    <input
                      value={Login}
                      type="text"
                      className="form-control"
                      id="loginUsuario"
                      name="loginUsuario"
                      placeholder="Login"
                      onChange={(e) => setLogin(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="loginUsuario" className="obrigatorio">
                      Login
                    </label>
                    <span className="input-group-text" id="basic-addon2">
                      @cm-sistemas.com
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-lg-4">
                  <div className="form-floating">
                    <input
                      value={senha}
                      type="password"
                      className="form-control"
                      id="senhaUsuario"
                      name="senhaUsuario"
                      placeholder="Senha"
                      onChange={(e) => setSenha(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="senhaUsuario" className="obrigatorio">
                      Senha
                    </label>
                  </div>
                </div>
                <div className="mb-3 col-lg-4">
                  <div className="form-floating">
                    <select
                      id="setor"
                      className="form-select mb-2"
                      aria-label="setor"
                      value={setor}
                      onChange={(e) => {
                        if (setor !== 'F') setSetor(e.target.value);
                      }}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    >
                      <option value="D">Desenvolvimento</option>
                      <option value="S">Suporte</option>
                      <option value="F" disabled>
                        Financeiro
                      </option>
                    </select>
                    <label htmlFor="setor" className="obrigatorio">
                      Setor
                    </label>
                  </div>
                </div>
                <div className="mb-3 col-lg-4">
                  <div className="row">
                    <div className="col-xs-6 col-md-6">
                      <div className="form-floating">
                        <select
                          id="administrador"
                          className="form-select mb-2"
                          aria-label="Administrador"
                          value={Administrador}
                          onChange={(e) => {
                            setAdministrador(e.target.value);
                          }}
                          disabled={permissoesUsuario.editar_incluir_usuario === 0}
                        >
                          <option value="S">Sim</option>
                          <option value="N">Não</option>
                        </select>
                        <label htmlFor="administrador" className="">
                          Administrador
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-6 col-md-6">
                      <div className="form-floating">
                        <select
                          id="disponivel"
                          className="form-select mb-2"
                          aria-label="Disponivel"
                          value={Ativo}
                          onChange={(e) => {
                            setAtivo(e.target.value);
                          }}
                          disabled={permissoesUsuario.editar_incluir_usuario === 0}
                        >
                          <option value="D">Sim</option>
                          <option value="I">Não</option>
                        </select>
                        <label htmlFor="disponivel" className="">
                          Disponivel
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-lg-5">
                  <div className="form-floating">
                    <input
                      value={maskphone(Telefone1)}
                      type="text"
                      className="form-control"
                      id="telefone1Usuario"
                      name="telefone1Usuario"
                      placeholder="Telefone 1"
                      onChange={(e) => setTelefone1(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="telefone1Usuario" className="">
                      Telefone 1
                    </label>
                  </div>
                </div>

                <div className="mb-3 col-lg-5">
                  <div className="form-floating">
                    <input
                      value={maskphone(Telefone2)}
                      type="text"
                      className="form-control"
                      id="telefone2Usuario"
                      name="telefone2Usuario"
                      placeholder="Telefone 2"
                      onChange={(e) => setTelefone2(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="telefone2Usuario" className="">
                      Telefone 2
                    </label>
                  </div>
                </div>
                <div className="mb-3 col-lg-2">
                  <div className="form-floating">
                    <input
                      value={dataAdmissao}
                      type="date"
                      className="form-control"
                      id="dataAdmissao"
                      name="dataAdmissao"
                      placeholder="Data de admissão"
                      onChange={(e) => setDataAdmissao(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="dataAdmissao" className="">
                      Data de admissão
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="mb-3 col-lg-4">
                  <div className="form-floating">
                    <input
                      value={cargo}
                      type="text"
                      className="form-control"
                      id="cargo"
                      name="cargo"
                      placeholder="Cargo"
                      onChange={(e) => setCargo(e.target.value)}
                      disabled={permissoesUsuario.editar_incluir_usuario === 0}
                    />
                    <label htmlFor="cargo" className="">
                      Cargo
                    </label>
                  </div>
                </div>
                <div className="mb-3 col-lg-4">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-floating">
                        <select
                          id="uf floatingSelectGrid"
                          className="form-select"
                          aria-label="Selecionar"
                          onChange={(e) => GetCidades(e.target.value)}
                          value={UF}
                          disabled={permissoesUsuario.editar_incluir_usuario === 0}
                        >
                          <option></option>
                          {listaUf.map((uf) => {
                            return (
                              <option key={uf.uf} value={uf.uf}>
                                {uf.uf}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="uf" className="">
                          UF
                        </label>
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="form-floating">
                        <select
                          id="cidades floatingSelectGrid"
                          className="form-select"
                          aria-label="Selecionar"
                          value={idCidade}
                          onChange={(e) => setIdCidade(e.target.value)}
                          disabled={permissoesUsuario.editar_incluir_usuario === 0}
                        >
                          <option></option>
                          {listaCidades.map((cidade) => {
                            return (
                              <option key={cidade.id} value={cidade.id}>
                                {cidade.nome}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="cidades" className="">
                          Cidade
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col">
                      <div className="form-floating">
                        <select
                          id="registra"
                          className="form-select mb-2"
                          aria-label="registra"
                          value={registraPonto}
                          onChange={(e) => {
                            setRegistraPonto(e.target.value);
                          }}
                          disabled={permissoesUsuario.editar_incluir_usuario === 0}
                        >
                          <option value={1}>Sim</option>
                          <option value={0}>Não</option>
                        </select>
                        <label htmlFor="registra" className="">
                          Registra Ponto
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
                  <button onClick={() => navigate(ROUTE_USUARIOS)} className="btn btn-danger">
                    Cancelar
                  </button>
                  <button className="btn btn-outline-primary" onClick={(e) => submitForm(e)}>
                    <FaFloppyDisk /> Gravar
                  </button>
                  <button type="button" className="btn btn-light" onClick={() => Limpar()}>
                    <FaEraser /> Limpar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsuariosEdicao;
