/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ProcuraCliente from '../../../../components/procura-cliente';
import { BuscaClienteListaAutoCompleteContext } from '../../../../context/BuscaClienteListaAutoCompleteContext';
import api from '../../../../service/api';
import { formataData, formataHora } from '../../../../util/maskdoc';
import CardAlerta from '../../../../components/alerta-card';
import './style.css';
import { UsuarioContext } from '../../../../context/userContext';
import { ST__IDUSUARIO, ST__PERMISSOES } from '../../../../constants/storagekey';
import FormOs from './formOs.jsx';
import FormAtualizacao from './formAtualizacao.jsx';
import { ROUTE_ATENDIMENTOS } from '../../../../constants/routes.js';
import Titulo from '../../../../components/titulo/index.jsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '../../../../constants/toast.jsx';
import { ClientesContext } from '../../../../context/clientesContext.jsx';
import ModalUploadArquivos from '../../../../components/modalUpload/index.jsx';

function NovoAtendimento({ usuarios }) {
  const queryClient = useQueryClient();
  const { idUsuario, nomeUsuario, permissoesUsuario, setPermissoesUsuario } = useContext(UsuarioContext);
  const navigate = useNavigate();
  const [contato, setContato] = useState('');
  const [encaminhado, setEncaminhado] = useState(idUsuario || localStorage.getItem(ST__IDUSUARIO));
  const [tipo, setTipo] = useState('');
  const [tipoOs, setTipoOs] = useState('');
  const [ocorrenciaSemFormatar, setOcorrenciaSemFormatar] = useState('');
  const [ocorrencia, setOcorrencia] = useState('');
  const [dataAgenda, setDataAgenda] = useState('');
  const [novoAtendimento, setNovoAtendimento] = useState({});
  const [alertas, setAlertas] = useState([]);
  const [disable, setDisable] = useState(false);
  const [versao, setVersao] = useState('');
  const [listaOs, setListaOs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [atendimentoDaApi, setAtendimentoDaApi] = useState({});
  const [id, setId] = useState(0);

  const { clienteId, achouCliente, cliente, setAchouCliente, setClienteId, setFocus } = useContext(BuscaClienteListaAutoCompleteContext);

  const { clientes: listaClientes } = useContext(ClientesContext);

  const FormByTipo = {
    O: FormOs({ setFocus, setTipoOs }),
    A: FormAtualizacao({ setFocus, setVersao, versao, listaOs, addLista }),
  };

  function addLista(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setListaOs((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setListaOs((prevSelectedItems) => prevSelectedItems.filter((item) => item !== value));
    }
  }

  useEffect(() => {
    if (clienteId === '' || isNaN(clienteId)) {
      setAchouCliente(false);
    }
    if (clienteId !== '') {
      setAchouCliente(true);
    }
  }, [clienteId]);

  useEffect(() => {
    if (clienteId === '' || clienteId === null) {
      let verificacao = listaClientes.filter((nome) => nome.nome_fantasia.toUpperCase().trim() === cliente.toUpperCase().trim());
      if (verificacao.length !== 0) {
        setClienteId(verificacao[0].id);
      } else {
        setAchouCliente(false);
      }
    }
    setNovoAtendimento({
      id_cliente: clienteId,
      contato,
      ocorrencia,
      id_equipe_lancamento: idUsuario,
      id_equipe_suporte: encaminhado,
      tipo,
      tipo_os: tipoOs,
      data_agenda: dataAgenda !== '' ? new Date(dataAgenda) : null,
      versao_atualizacao: versao,
      os_relacionadas: listaOs,
      situacao: 'P',
    });
  }, [clienteId, cliente, contato, encaminhado, tipo, tipoOs, ocorrencia, versao, listaOs, dataAgenda]);

  useEffect(() => {
    let moment = new Date().toISOString();
    setOcorrencia(`${formataData(moment)} ${formataHora(moment)} | ${nomeUsuario} | ${ocorrenciaSemFormatar}\n`);
  }, [ocorrenciaSemFormatar]);

  const NovoAtendimentoRequest = async (e) => {
    setDisable(true);
    e.preventDefault();
    let erros = [];
    if (achouCliente === false) erros.push('CLIENTE');
    if (tipo === '') erros.push('TIPO');
    if (ocorrenciaSemFormatar === '') erros.push('OCORRENCIA');
    if (tipo === 'L' && dataAgenda === '') erros.push('DATA DE AGENDAMENTO');
    if (erros.length > 0) {
      setDisable(false);
      return Swal.fire(`${erros.join(', ').replace(/,(?=[^,]*$)/, ' e')} incorreto(s)`);
    }

    if (tipo !== 'O') {
      delete novoAtendimento.tipo_os;
    }
    if (tipo === 'O') {
      delete novoAtendimento.data_agenda;
    }
    if (tipo !== 'A') {
      delete novoAtendimento.versao_atualizacao;
      delete novoAtendimento.os_relacionadas;
    }

    await requestNovoAtendimento(novoAtendimento);
  };

  async function apiPostNovoAtendimento(infos) {
    try {
      const response = await api.post('/v1/atendimentos/inserir', infos);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  const { mutateAsync: requestNovoAtendimento } = useMutation({
    mutationFn: apiPostNovoAtendimento,
    onError: (error) => {
      setDisable(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao registrar o atendimento!',
        text: error.response.status === 400 ? error.response.data.join(', ') : '',
      });
    },
    onSuccess: async (dados, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['atendimentos'] });
      queryClient.prefetchQuery({
        queryKey: ['atendimentos'],
      });
      if (Number(variables.id_equipe_suporte) !== Number(idUsuario) && variables.tipo === 'O') {
        const { isConfirmed } = await Swal.fire({
          icon: 'question',
          title: 'Deseja anexar arquivos a esta O.S.?',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        });
        if (isConfirmed) {
          setAtendimentoDaApi(dados.atendimento);
          setId(dados.atendimento.id);
          setModalIsOpen(true);

          return;
        }
      }
      if (Number(variables.id_equipe_suporte) !== Number(idUsuario)) {
        toast.fire({
          icon: 'success',
          title: 'Atendimento encaminhado com sucesso!',
        });
      }
      navigate(ROUTE_ATENDIMENTOS);
    },
  });

  function fecharModalUpload() {
    setModalIsOpen(false);
    navigate(ROUTE_ATENDIMENTOS);
  }

  useEffect(() => {
    if (clienteId) {
      api.get(`/v1/alerta_clientes/${clienteId}`).then((result) => {
        setAlertas(result.data);
      });
    }
  }, [clienteId]);

  const closeAlertaCard = (id) => {
    setAlertas(alertas.filter((x) => x.id !== id));
  };

  useEffect(() => {
    if (!permissoesUsuario.hasOwnProperty('encaminhar_atendimento')) {
      setPermissoesUsuario(JSON.parse(localStorage.getItem(ST__PERMISSOES)));
    }
  }, [permissoesUsuario]);

  return (
    <>
      <ModalUploadArquivos isOpen={modalIsOpen} onRequestClose={fecharModalUpload} atendimento={atendimentoDaApi} id={id} />
      <div className="container-alerta">
        {alertas.length > 0 ? alertas.map((x) => <CardAlerta alerta={x} key={x.id} onClose={closeAlertaCard} />) : null}
      </div>
      {/* TITULO */}
      <Titulo titulo="Novo atendimento:" retorno="atendimentos" />
      {/* INICIO DO FORMS */}
      <div className="row ps-2 mt-3">
        <div className="row mb-3 gx-2 gy-2 ps-3 pe-3" data-bss-baguettebox>
          <form id="form-cliente">
            <div className="row">
              <ProcuraCliente editar={true} />
              <div className="mb-3 col-md-4 col-lg-4 col-sm-4">
                <div className="form-floating">
                  <input
                    onChange={(e) => setContato(e.target.value)}
                    value={contato}
                    onFocus={(e) => setFocus(false)}
                    onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="contato"
                    name="contato"
                    placeholder="Contato"
                  />
                  <label htmlFor="contato" className="obrigatorio">
                    Contato
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6 col-lg-6 col-sm-6">
                <div className="form-floating">
                  <select
                    id="encaminhado floatingSelectGrid"
                    onFocus={(e) => setFocus(false)}
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => setEncaminhado(parseInt(e.target.value, 10))}
                    value={encaminhado}
                    defaultValue={encaminhado}
                  >
                    {permissoesUsuario.encaminhar_atendimento === 1 ? (
                      usuarios.map((usuario) => {
                        return (
                          <option key={usuario.ID} value={usuario.ID}>
                            {usuario.NOME}
                          </option>
                        );
                      })
                    ) : (
                      <option value={encaminhado}>{nomeUsuario}</option>
                    )}
                  </select>
                  <label htmlFor="encaminhado" className="obrigatorio">
                    Encaminhar
                  </label>
                </div>
              </div>
              <div className="mb-3 col-md-6 col-lg-6 col-sm-6">
                <div className="form-floating">
                  <select
                    id="tipo"
                    className="form-select mb-2"
                    onFocus={(e) => setFocus(false)}
                    aria-label="Selecionar"
                    onChange={(e) => setTipo(e.target.value)}
                  >
                    <option value="">Selecionar</option>
                    <option value="R">Acesso Remoto</option>
                    <option value="L">Visita ao local</option>
                    <option value="O">O.S. Alteração do sistema</option>
                    <option value="A">Atualização do sistema</option>
                    <option value="T">Telefone</option>
                  </select>
                  <label htmlFor="tipo" className="obrigatorio">
                    Tipo
                  </label>
                </div>
              </div>
              {tipo !== 'O' ? (
                <div className="col-12 col-md-4">
                  <div className="form-floating">
                    <input
                      onChange={(e) => setDataAgenda(e.target.value)}
                      value={dataAgenda}
                      type="datetime-local"
                      className="form-control mb-2"
                      id="edDataInicial"
                      placeholder=""
                    />
                    <label htmlFor="edDataInicial">Data de Agendamento</label>
                  </div>
                </div>
              ) : null}
              {tipo !== '' ? FormByTipo[tipo] : null}
            </div>
            <div className="row">
              <div className="col">
                <div className="">
                  <label htmlFor="ocorrencia" className="obrigatorio">
                    Ocorrência:
                  </label>
                  <textarea
                    className="form-control"
                    id="ocorrencia"
                    rows={4}
                    onFocus={(e) => setFocus(false)}
                    onChange={(e) => setOcorrenciaSemFormatar(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
                <button
                  className="btn btn-outline-primary"
                  id="submitBtn"
                  onClick={(e) => {
                    NovoAtendimentoRequest(e);
                  }}
                  disabled={disable}
                >
                  Gravar
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(ROUTE_ATENDIMENTOS);
                  }}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default NovoAtendimento;
