import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './style.css';
import UsoModal from '../../components/uso-modal';

import api from '../../service/api';
import { formataData, formataHora } from '../../util/maskdoc.js';
import { FaChevronLeft, FaLocationDot, FaTrash } from 'react-icons/fa6';

function UsoSiaacDispositivos() {
  const { contrato, empresa } = useParams();
  const [dispositivos, setDispositivos] = useState([]);
  const [OpenFormsModal, setOpenFormsModal] = useState(false);
  const [ip, setIp] = useState('');

  async function GetDispositivosUso() {
    api
      .get(`/v1/usosiaac/dispositivos/${contrato}`)
      .then((response) => {
        if (response.status === 200) {
          setDispositivos(response.data);
        } else {
          if (response.status === 401) {
          } else {
            console.log('Ocorreu algum erro na requisição de contratos: ' + response.statusText);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
        } else {
        }
      });
  }

  function closeFormsModal() {
    setOpenFormsModal(false);
  }

  function OpenModal(ipAddress) {
    setIp(ipAddress);
    setOpenFormsModal(true);
  }

  useEffect(() => {
    GetDispositivosUso();
  }, [ip]);

  return (
    <div>
      <UsoModal isOpen={OpenFormsModal} onRequestClose={closeFormsModal} endereco={ip} />

      <div className="container shadow-lg rounded-2 mt-3">
        <div className="row justify-content-between align-items-center mb-2 mt-3">
          <div className="row">
            <div className="col-lg-1 col-sm-2 mt-2 ps-3">
              <Link className="btn btn-lg btn-light btn-rounded" to="/usosiaac">
                <FaChevronLeft style={{ margin: '0 0 3px 0' }} size={20} />
              </Link>
            </div>
            <div className="col-lg-11 col-sm-10 mb-1">
              <h5 className="text-dark mt-3 titulo">Uso do SIAAC - Dispositivos</h5>
            </div>
          </div>
        </div>

        <div className="row ps-2 mt-3">
          <div className="col-lg-11 col-sm-10 mb-1">
            <span className="text-dark mt-3">
              Cliente:{' '}
              <strong className="text-danger">
                ({contrato}) {empresa}
              </strong>
            </span>
          </div>
          <div className="row mb-3 gx-2 gy-2 ps-3 pe-3" data-bss-baguettebox>
            <form id="form-dispositivos" action="">
              <table className="table table-sm table-striped table-hover table-responsive" style={{ width: '100%' }}>
                <thead className="table-light">
                  <tr>
                    <th style={{ width: '10%' }}>Data</th>
                    <th style={{ width: '10%' }}>Hora</th>
                    <th>Dispositivo</th>
                    <th>Usuário</th>
                    <th style={{ width: '9%', textAlign: 'start' }}>Versão</th>
                    <th style={{ width: '10%', textAlign: 'center' }}>IP</th>
                    <th style={{ width: '10%', textAlign: 'center' }}></th>
                    <th style={{ width: '8%', textAlign: 'center' }}>Opções</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {dispositivos.map((dispositivo, index) => {
                    return (
                      <tr key={index}>
                        <th>{formataData(dispositivo.data_hora)}</th>
                        <th>{formataHora(dispositivo.data_hora)}</th>
                        <td>{dispositivo.computador}</td>
                        <td>{dispositivo.usuario_windows}</td>
                        <td>{dispositivo.versao_siaac}</td>
                        <td>{dispositivo.address.replace('::ffff:', '')}</td>
                        <td>
                          <button
                            onClick={() => {
                              setIp(dispositivo.address.replace('::ffff:', ''));
                              OpenModal(dispositivo.address.replace('::ffff:', ''));
                            }}
                            type="button"
                            className="ms-4 btn btn-sm btn-primary"
                          >
                            <FaLocationDot />
                          </button>
                        </td>
                        <td>
                          <div style={{ 'text-align': 'center' }}>
                            <button type="button" className="btn btn-sm btn-danger" disabled>
                              <FaTrash className="me-2" />
                              <span>Excluir</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsoSiaacDispositivos;
