import React, { useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { UsuarioContext } from '../../context/userContext';
import {
  ROUTE_ATENDIMENTOS,
  ROUTE_CLIENTES,
  ROUTE_CONFIGURACOES,
  ROUTE_CONFIGURACOES_PERMISSOES,
  ROUTE_CONFIGURACOES_USUARIO,
  ROUTE_FINANCEIRO,
  ROUTE_FINANCEIRO_CONCILIACAO,
  ROUTE_FINANCEIRO_CONFERENCIA,
  ROUTE_HORARIOS,
  ROUTE_LOGIN,
  ROUTE_RELATORIO,
  ROUTE_RELATORIO_ATENDIMENTO_POR_CLIENTE,
  ROUTE_RELATORIO_ATENDIMENTO_POR_EQUIPE,
  ROUTE_RELATORIO_ATENDIMENTO_POR_TIPO,
  ROUTE_RELATORIO_CLIENTES_DATA_CADASTRO,
  ROUTE_RELATORIO_PICO_ATENDIMENTO,
  ROUTE_USOSIAAC,
  ROUTE_USUARIOS,
} from '../../constants/routes.js';
import { ST__IDUSUARIO } from '../../constants/storagekey.js';
import {
  FaAddressCard,
  FaArrowRightToBracket,
  FaChartSimple,
  FaCommentsDollar,
  FaDisplay,
  FaEnvelope,
  FaLock,
  FaMoneyBillTrendUp,
  FaPrint,
  FaUserGear,
} from 'react-icons/fa6';
import { useQueryClient } from '@tanstack/react-query';
import { BsCalendar2DateFill } from 'react-icons/bs';
import Logo from '../../assets/logo-sm.png';
import { GrSystem } from 'react-icons/gr';
import ChaveDeAtivacao from '../modalChaveAtivacao/index.jsx';

function Navbar() {
  const queryClient = useQueryClient();
  const { permissoesUsuario, nomeUsuario, emailUsuario } = useContext(UsuarioContext);
  const navigate = useNavigate();

  let location = useLocation();

  function Logout() {
    queryClient.clear();
    localStorage.clear();
    navigate(ROUTE_LOGIN);
  }

  const closeDropdown = () => {
    const dropdowns = document.querySelectorAll('.dropdown-menu.show');
    dropdowns.forEach((dropdown) => {
      dropdown.classList.remove('show');
    });
  };

  return (
    <nav className="navbar navbar-dark navbar-expand-xl bg-primary py-2">
      <div className="container-fluid">
        <Link className="navbar-brand risco" to="/">
          <img src={Logo} alt="Logo" width="40" className="d-inline-block align-text-top" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname.startsWith(ROUTE_ATENDIMENTOS) ? 'active' : null}`}
                aria-current="page"
                to={ROUTE_ATENDIMENTOS}
              >
                Atendimentos
              </Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${location.pathname.startsWith(ROUTE_CLIENTES) ? 'active' : null}`} to={ROUTE_CLIENTES}>
                Clientes
              </Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${location.pathname.startsWith(ROUTE_USUARIOS) ? 'active' : null}`} to={ROUTE_USUARIOS}>
                Usuários
              </Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${location.pathname.startsWith(ROUTE_HORARIOS) ? 'active' : null}`} to={ROUTE_HORARIOS}>
                Registro de Horário
              </Link>
            </li>

            <li className="nav-item" hidden={permissoesUsuario.uso_siaac === 0}>
              <Link
                className={`nav-link position-relative ${location.pathname.startsWith(ROUTE_USOSIAAC) ? 'active' : null}`}
                to={ROUTE_USOSIAAC}
              >
                Uso do SIAAC
              </Link>
            </li>

            <li className="nav-item dropdown" hidden={permissoesUsuario.financeiro === 0}>
              <a
                className={`nav-link dropdown-toggle ${location.pathname.startsWith(ROUTE_FINANCEIRO) ? 'active' : null}`}
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Financeiro
              </a>
              <ul className="dropdown-menu" onClick={closeDropdown}>
                <li>
                  <Link className="dropdown-item" to={ROUTE_FINANCEIRO_CONFERENCIA} href="#">
                    <FaMoneyBillTrendUp /> Conferência de recebimento
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={ROUTE_FINANCEIRO_CONCILIACAO}>
                    <FaCommentsDollar size={20} /> Conciliação do cliente
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle ${location.pathname.startsWith(ROUTE_RELATORIO) ? 'active' : null}`}
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Relatórios
              </a>
              <ul className="dropdown-menu" onClick={closeDropdown}>
                <li>
                  <Link className="dropdown-item" to={ROUTE_RELATORIO_ATENDIMENTO_POR_EQUIPE}>
                    <FaPrint /> Atendimento por Equipe
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={ROUTE_RELATORIO_ATENDIMENTO_POR_CLIENTE}>
                    <FaPrint /> Atendimento por Cliente
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={ROUTE_RELATORIO_ATENDIMENTO_POR_TIPO}>
                    <FaPrint /> Atendimento por Tipo
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to={ROUTE_RELATORIO_PICO_ATENDIMENTO}>
                    <FaChartSimple /> Pico de atendimentos
                  </Link>
                </li>
                {permissoesUsuario.financeiro === 1 ? (
                  <>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to={ROUTE_RELATORIO_CLIENTES_DATA_CADASTRO}>
                        <BsCalendar2DateFill /> Clientes por Data de Cadastro
                      </Link>
                    </li>
                  </>
                ) : null}
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle ${location.pathname.startsWith(ROUTE_CONFIGURACOES) ? 'active' : null}`}
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Configurações
              </a>
              <ul className="dropdown-menu" onClick={closeDropdown}>
                {[6, 13, 20, 37].includes(parseInt(localStorage.getItem(ST__IDUSUARIO))) ? (
                  <li>
                    <Link className="dropdown-item" to={ROUTE_CONFIGURACOES_PERMISSOES}>
                      <FaUserGear size={18} /> Permissões Usuário
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link className="dropdown-item" to={ROUTE_CONFIGURACOES_USUARIO}>
                    <FaDisplay size={18} /> Visualização
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <form className="d-flex">
            {permissoesUsuario.chave_ativacao === 1 ? (
              <div className="dropdown me-2">
                <button
                  className="btn btn btn-outline-light dropdown-toggle border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <GrSystem />
                </button>
                <ul className="dropdown-menu dropdown-menu-xl-end">
                  <li>
                    <ChaveDeAtivacao className="dropdown-item" />
                  </li>
                </ul>
              </div>
            ) : null}
            <div className="dropdown">
              <button className="btn btn btn-outline-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {nomeUsuario}
              </button>
              <ul className="dropdown-menu dropdown-menu-xl-end">
                <li>
                  <a className="dropdown-item disabled" href="#">
                    <FaEnvelope /> {emailUsuario}
                  </a>
                </li>
                <li>
                  <Link className={`nav-link`} to="/perfil#account">
                    <a className="dropdown-item" href="#account">
                      <FaAddressCard /> Perfil
                    </a>
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/perfil#password">
                    <a className="dropdown-item" href="/perfil#password">
                      <FaLock /> Trocar senha
                    </a>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" onClick={(e) => Logout()} href="/#">
                    <FaArrowRightToBracket /> Logout
                  </a>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default React.memo(Navbar);
